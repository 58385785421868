import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import axios from '@axios'
import ability from '@/libs/acl/ability'
import { canNavigate } from '@/libs/acl/routeProtection'

import AgingReport from './aging-report'
import CarMasterMaintainance from './car-master-maintainance'
import DailyReceiveReport from './daily-receive-report'
import DropRental from './drop-rental'
import RepaymentInquiry from './repayment-inquiry'
import Example from './example'
import DigitalRepayment from './digital-repayment'
import contractMaker from './contract-maker'
import contractApprove from './contract-approve'
import contractPrinting from './contract-printing'
import inquiryReceive from './inquiry-receive'
import member from './member'
import memberType from './member-type'
import department from './department'
import position from './position'
import permission from './permission'
import system from './system'
import contractInquiry from './contract-inquiry'
import CarMasterStatus from './car-master-status'
import changePassword from './change-password'
import CarParking from './car-parking'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        resource: 'Auth',
        action: 'read',
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    ...AgingReport,
    ...CarMasterMaintainance,
    ...DailyReceiveReport,
    ...DropRental,
    ...RepaymentInquiry,
    ...Example,
    ...DigitalRepayment,
    ...contractMaker,
    ...contractApprove,
    ...contractPrinting,
    ...inquiryReceive,
    ...contractInquiry,
    ...CarMasterStatus,
    ...member,
    ...memberType,
    ...department,
    ...position,
    ...permission,
    ...system,
    ...changePassword,
    ...CarParking,

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        resource: 'Auth',
        action: 'read',
        redirectIfLoggedIn: true,
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        resource: 'Auth',
        action: 'read',
        layout: 'full',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/NotAuthorized.vue'),
      meta: {
        resource: 'Auth',
        action: 'read',
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
// eslint-disable-next-line consistent-return
router.beforeEach((to, _, next) => {
  if (to.path === '/login') return next()

  const isLoggedIn = isUserLoggedIn()

  if (!isLoggedIn) return next({ name: 'login' })

  axios
    .post('/auth/validateToken')
    .then(response => {
      const userData = response.data
      localStorage.setItem('userData', JSON.stringify(userData)) // set localstorage ข้อมูลล็อกอิน

      const getAbility = ability
      if (userData.ability) getAbility.update(userData.ability)

      // return next()
    })
    .catch(() => {
      // console.log('fail validate from catch', error)
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')

      next({ name: 'login' })
    })

  if (!canNavigate(to)) {
    console.log('cannot access')
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
    // return next({ name: 'login' })
  }

  return next()
})
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
