export default [{
  path: '/change_password',
  name: 'change-password',
  component: () => import('@/views/change-password/ChangePassword.vue'),
  meta: {
    resource: 'Auth',
    action: 'read',
    pageTitle: 'เปลี่ยนรหัสผ่าน',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'เปลี่ยนรหัสผ่าน',
      active: true,
    },
    ],
  },
},
]
