export default [{
  path: '/contract_printing',
  name: 'contract-printing',
  component: () => import('@/views/contract-printing/ContractPrintingIndex.vue'),
  meta: {
    resource: 'ContractPrinting',
    action: 'read',
    pageTitle: 'สร้าง QR Code',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'สร้าง QR Code',
      active: true,
    },
    ],
  },
},
]
