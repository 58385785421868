export default [{
  path: '/position',
  name: 'position',
  component: () => import('@/views/position/Position.vue'),
  meta: {
    resource: 'Position',
    action: 'read',
    pageTitle: 'ตำแหน่ง',
    pageIcon: 'UserIcon',
    breadcrumb: [{
      text: 'ตำแหน่ง',
      active: true,
    },
    ],
  },
},
{
  path: '/position/form',
  name: 'position-form',
  component: () => import('@/views/position/PositionForm.vue'),
  meta: {
    resource: 'Position',
    action: 'read',
    pageTitle: 'ตำแหน่ง',
    pageIcon: 'UserIcon',
    navActiveLink: 'position',
    breadcrumb: [{
      text: 'ตำแหน่ง',
      to: { name: 'position' },
    },
    {
      text: 'เพิ่มข้อมูล',
      active: true,
    },
    ],
  },
},
{
  path: '/position/form/:id',
  name: 'position-form-edit',
  component: () => import('@/views/position/PositionForm.vue'),
  meta: {
    resource: 'Position',
    action: 'read',
    pageTitle: 'ตำแหน่ง',
    pageIcon: 'UserIcon',
    navActiveLink: 'position',
    breadcrumb: [{
      text: 'ตำแหน่ง',
      to: { name: 'position' },
    },
    {
      text: 'แก้ไขข้อมูล',
      active: true,
    },
    ],
  },
},
]
