export default [{
  path: '/contract_maker',
  name: 'contract-maker',
  component: () => import('@/views/contract-maker/ContractMakerIndex.vue'),
  meta: {
    resource: 'ContractMaker',
    action: 'read',
    pageTitle: 'สร้างรายการสัญญา',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'สร้างรายการสัญญา',
      active: true,
    },
    ],
  },
},
{
  path: '/contract_maker/form',
  name: 'contract-maker-form-add',
  component: () => import('@/views/contract-maker/ContractMakerForm.vue'),
  meta: {
    pageTitle: 'สร้างรายการสัญญา',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'สร้างรายการสัญญา',
      to: { name: 'contract-maker' },
    },
    {
      text: 'เพิ่มข้อมูล',
      active: true,
    },
    ],
  },
},
{
  path: '/contract_maker/form/:connum',
  name: 'contract-maker-form-edit',
  component: () => import('@/views/contract-maker/ContractMakerForm.vue'),
  meta: {
    pageTitle: 'สร้างรายการสัญญา',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'สร้างรายการสัญญา',
      to: { name: 'contract-maker' },
    },
    {
      text: 'แก้ไขข้อมูล',
      active: true,
    },
    ],
  },
},
{
  path: '/contract_maker/remove/:connum',
  name: 'contract-maker-form-remove',
  component: () => import('@/views/contract-maker/ContractMakerForm.vue'),
  meta: {
    pageTitle: 'สร้างรายการสัญญา',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'สร้างรายการสัญญา',
      to: { name: 'contract-maker' },
    },
    {
      text: 'ลบข้อมูล',
      active: true,
    },
    ],
  },
},
]
