export default [{
  path: '/daily_receive_report',
  name: 'daily-receive-report',
  component: () => import('@/views/daily-receive-report/DailyReceiveReportIndex.vue'),
  meta: {
    resource: 'DailyReceiveReport',
    action: 'read',
    pageTitle: 'รายงานการรับค่าเช่ารายวัน',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'รายงานการรับค่าเช่ารายวัน',
      active: true,
    },
    ],
  },
},
]
