export default [{
  path: '/digital_repayment',
  name: 'digital-repayment',
  component: () => import('@/views/digital-repayment/DigitalRepaymentIndex.vue'),
  meta: {
    resource: 'DigitalRepayment',
    action: 'read',
    pageTitle: 'สถานะรับชำระและแก้ไขข้อมูล',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'สถานะรับชำระและแก้ไขข้อมูล',
      active: true,
    },
    ],
  },
},
]
