export default [{
  path: '/aging_report',
  name: 'aging-report',
  component: () => import('@/views/aging-report/AgingReportIndex.vue'),
  meta: {
    resource: 'AgingReport',
    action: 'read',
    pageTitle: 'รายงานอายุหนี้',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'รายงานอายุหนี้',
      active: true,
    },
    ],
  },
},
]
