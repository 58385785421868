export default [{
  path: '/repayment_inquiry',
  name: 'repayment-inquiry',
  component: () => import('@/views/repayment-inquiry/RepaymentInquiryIndex.vue'),
  meta: {
    resource: 'RepaymentInquiry',
    action: 'read',
    pageTitle: 'ประวัติการรับชำระ',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'ประวัติการรับชำระ',
      active: true,
    },
    ],
  },
},
]
