export default [{
  path: '/drop_rental',
  name: 'drop-rental',
  component: () => import('@/views/drop-rental/DropRentalIndex.vue'),
  meta: {
    resource: 'DropRental',
    action: 'read',
    pageTitle: 'ปรับปรุงค่าเช่า',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'ปรับปรุงค่าเช่า',
      active: true,
    },
    ],
  },
},
]
