import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  Authorization: 'JWT ',
  // ================================
  baseURL: 'http://www.pnptaxi.com/APIV1/api', // Production server
  // baseURL: 'http://localhost:54894/api/', // virtual server
  // baseURL: '/APIV1/api', // UAT server
  // baseURL: 'http://43.229.134.27:8080/APIV1/api', // test Dev server
  // baseURL: 'http://localhost:53596/api/',
  // baseURL: 'http://localhost:8080/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

// Alter defaults after instance has been created
// const token = `Bearer ${localStorage.getItem('accessToken')}`
// axiosIns.defaults.headers.common.Authorization = `Bearer ${token}`

axiosIns.interceptors.request.use(config => {
  // Get token from localStorage
  const accessToken = localStorage.getItem('accessToken')

  // If token is present add it to request's Authorization Header
  if (accessToken) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`
    // console.log(`${this.jwtConfig.tokenType} ${accessToken}`)
  }
  return config
},
error => Promise.reject(error))

Vue.prototype.$http = axiosIns

export default axiosIns
