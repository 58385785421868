export default [{
  path: '/contract_approve',
  name: 'contract-approve',
  component: () => import('@/views/contract-approve/ContractApproveIndex.vue'),
  meta: {
    resource: 'ContractApprove',
    action: 'read',
    pageTitle: 'อนุมัติรายการสัญญา',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'อนุมัติรายการสัญญา',
      active: true,
    },
    ],
  },
},
{
  path: '/contract_approve/form/:connum',
  name: 'contract-approve-form',
  component: () => import('@/views/contract-approve/ContractApproveForm.vue'),
  meta: {
    pageTitle: 'อนุมัติรายการสัญญา',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'อนุมัติรายการสัญญา',
      to: { name: 'contract-approve' },
    },
    {
      text: 'ฟอร์มอนุมัติ',
      active: true,
    },
    ],
  },
},
]
