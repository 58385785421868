export default [{
  path: '/permission',
  name: 'permission',
  component: () => import('@/views/permission/Permission.vue'),
  meta: {
    resource: 'Permission',
    action: 'read',
    pageTitle: 'กำหนดสิทธิผู้ใช้',
    pageIcon: 'UnlockIcon',
    breadcrumb: [{
      text: 'กำหนดสิทธิผู้ใช้',
      active: true,
    },
    ],
  },
},
{
  path: '/permission/form/:id',
  name: 'permission-form',
  component: () => import('@/views/permission/PermissionForm.vue'),
  meta: {
    resource: 'Permission',
    action: 'read',
    pageTitle: 'กำหนดสิทธิผู้ใช้',
    pageIcon: 'UnlockIcon',
    navActiveLink: 'permission',
    breadcrumb: [{
      text: 'กำหนดสิทธิผู้ใช้',
      to: { name: 'permission' },
    },
    {
      text: 'ฟอร์มแก้ไข',
      active: true,
    },
    ],
  },
},
]
