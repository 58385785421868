export default [{
  path: '/member_type',
  name: 'member-type',
  component: () => import('@/views/member-type/MemberType.vue'),
  meta: {
    resource: 'MemberType',
    action: 'read',
    pageTitle: 'ประเภทผู้ใช้งาน',
    pageIcon: 'UserIcon',
    breadcrumb: [{
      text: 'ประเภทผู้ใช้งาน',
      active: true,
    },
    ],
  },
},
{
  path: '/member_type/form',
  name: 'member-type-form',
  component: () => import('@/views/member-type/MemberTypeForm.vue'),
  meta: {
    resource: 'MemberType',
    action: 'read',
    pageTitle: 'ประเภทผู้ใช้งาน',
    pageIcon: 'UserIcon',
    navActiveLink: 'member-type',
    breadcrumb: [{
      text: 'ประเภทผู้ใช้งาน',
      to: { name: 'member-type' },
    },
    {
      text: 'เพิ่มข้อมูล',
      active: true,
    },
    ],
  },
},
{
  path: '/member_type/form/:id',
  name: 'member-type-form-edit',
  component: () => import('@/views/member-type/MemberTypeForm.vue'),
  meta: {
    resource: 'MemberType',
    action: 'read',
    pageTitle: 'ประเภทผู้ใช้งาน',
    pageIcon: 'UserIcon',
    navActiveLink: 'member-type',
    breadcrumb: [{
      text: 'ประเภทผู้ใช้งาน',
      to: { name: 'member-type' },
    },
    {
      text: 'แก้ไขข้อมูล',
      active: true,
    },
    ],
  },
},
]
