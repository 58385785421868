export default [{
  path: '/member',
  name: 'member',
  component: () => import('@/views/member/Member.vue'),
  meta: {
    resource: 'Member',
    action: 'read',
    pageTitle: 'ผู้ใช้งานระบบ',
    pageIcon: 'UserIcon',
    breadcrumb: [{
      text: 'ผู้ใช้งานระบบ',
      active: true,
    },
    ],
  },
},
{
  path: '/member/form',
  name: 'member-form',
  component: () => import('@/views/member/MemberForm.vue'),
  meta: {
    resource: 'Member',
    action: 'read',
    pageTitle: 'ผู้ใช้งานระบบ',
    pageIcon: 'UserIcon',
    navActiveLink: 'member',
    breadcrumb: [{
      text: 'ผู้ใช้งานระบบ',
      to: { name: 'member' },
    },
    {
      text: 'เพิ่มข้อมูล',
      active: true,
    },
    ],
  },
},
{
  path: '/member/form/:id',
  name: 'member-form-edit',
  component: () => import('@/views/member/MemberForm.vue'),
  meta: {
    resource: 'Member',
    action: 'read',
    pageTitle: 'ผู้ใช้งานระบบ',
    pageIcon: 'UserIcon',
    navActiveLink: 'member',
    breadcrumb: [{
      text: 'ผู้ใช้งานระบบ',
      to: { name: 'member' },
    },
    {
      text: 'แก้ไขข้อมูล',
      active: true,
    },
    ],
  },
},
]
