export default [{
  path: '/contract_inquiry',
  name: 'contract-inquiry',
  component: () => import('@/views/contract-inquiry/ContractInquiryIndex.vue'),
  meta: {
    resource: 'ContractInquiry',
    action: 'read',
    pageTitle: 'เรียกดูรายการสัญญา',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'เรียกดูรายการสัญญา',
      active: true,
    },
    ],
  },
},
{
  path: '/contract_inquiry/form/:connum',
  name: 'contract-inquiry-form',
  component: () => import('@/views/contract-inquiry/ContractInquiryForm.vue'),
  meta: {
    pageTitle: 'เรียกดูรายการสัญญา',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'เรียกดูรายการสัญญา',
      to: { name: 'contract-inquiry' },
    },
    {
      text: 'เรียกดูรายการสัญญา',
      active: true,
    },
    ],
  },
},
]
