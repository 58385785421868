export default [{
  path: '/car_master_maintainance',
  name: 'car-master-maintainance',
  component: () => import('@/views/car-master-maintainance/CarMasterMaintainanceIndex.vue'),
  meta: {
    pageTitle: 'ข้อมูลหลักรถยนต์',
    pageIcon: 'BoldIcon',
    resource: 'CarMasterMaintainance',
    action: 'read',
    breadcrumb: [{
      text: 'ข้อมูลหลักรถยนต์',
      active: true,
    },
    ],
  },
},
{
  path: '/car_master_maintainance/form',
  name: 'car-master-maintainance-form-add',
  component: () => import('@/views/car-master-maintainance/CarMasterMaintainanceForm.vue'),
  meta: {
    pageTitle: 'ข้อมูลหลักรถยนต์',
    pageIcon: 'BoldIcon',
    resource: 'CarMasterMaintainance',
    action: 'manage',
    breadcrumb: [{
      text: 'ข้อมูลหลักรถยนต์',
      to: { name: 'car-master-maintainance' },
    },
    {
      text: 'เพิ่มข้อมูล',
      active: true,
    },
    ],
  },
},
{
  path: '/car_master_maintainance/form/:branch_code/:chassis_number',
  name: 'car-master-maintainance-form-edit',
  component: () => import('@/views/car-master-maintainance/CarMasterMaintainanceForm.vue'),
  meta: {
    pageTitle: 'ข้อมูลหลักรถยนต์',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'ข้อมูลหลักรถยนต์',
      to: { name: 'car-master-maintainance' },
    },
    {
      text: 'แก้ไขข้อมูล',
      active: true,
    },
    ],
  },
},
{
  path: '/car_master_maintainance/remove/:branch_code/:chassis_number',
  name: 'car-master-maintainance-form-remove',
  component: () => import('@/views/car-master-maintainance/CarMasterMaintainanceForm.vue'),
  meta: {
    pageTitle: 'ข้อมูลหลักรถยนต์',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'ข้อมูลหลักรถยนต์',
      to: { name: 'car-master-maintainance' },
    },
    {
      text: 'ลบข้อมูล',
      active: true,
    },
    ],
  },
},
]
