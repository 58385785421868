export default [{
  path: '/example',
  name: 'exampleRoute',
  component: () => import('@/views/example/Example.vue'),
  meta: {
    pageTitle: 'ทดสอบเขียน',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'ทดสอบเขียน',
      active: true,
    },
    ],
  },
},
]
