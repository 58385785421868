export default [{
  path: '/car_parking',
  name: 'car-parking',
  component: () => import('@/views/car-parking/CarParkingIndex.vue'),
  meta: {
    resource: 'CarParking',
    action: 'read',
    pageTitle: 'ที่จอดรถ',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'ที่จอดรถ',
      active: true,
    },
    ],
  },
},
{
  path: '/car_parking/form',
  name: 'car-parking-form-add',
  component: () => import('@/views/car-parking/CarParkingForm.vue'),
  meta: {
    pageTitle: 'ที่จอดรถ',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'ที่จอดรถ',
      to: { name: 'car-master-parking' },
    },
    {
      text: 'เพิ่มข้อมูล',
      active: true,
    },
    ],
  },
},
{
  path: '/car_parking_edit/:regnum1/:regnum2',
  name: 'car-parking-edit',
  component: () => import('@/views/car-parking/CarParkingEdit.vue'),
  meta: {
    pageTitle: 'ที่จอดรถ',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'ที่จอดรถ',
      to: { name: 'car-master-parking' },
    },
    {
      text: 'แก้ไขข้อมูล',
      active: true,
    },
    ],
  },
},
{
  path: '/car_parking_view/:regnum1/:regnum2',
  name: 'car-parking-view',
  component: () => import('@/views/car-parking/CarParkingView.vue'),
  meta: {
    pageTitle: 'ที่จอดรถ',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'ที่จอดรถ',
      to: { name: 'car-master-parking' },
    },
    {
      text: 'เรียกดูข้อมูล',
      active: true,
    },
    ],
  },
},
]
