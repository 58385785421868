export default [{
  path: '/car_master_status',
  name: 'car-master-status',
  component: () => import('@/views/car-master-status/CarMasterStatusIndex.vue'),
  meta: {
    resource: 'CarMasterStatus',
    action: 'read',
    pageTitle: 'ปรับปรุงสถานะรถยนต์',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'ปรับปรุงสถานะรถยนต์',
      active: true,
    },
    ],
  },
},
// {
//   path: '/car_master_status/form/:branch_code/:chassis_number',
//   name: 'car-master-status-form-update',
//   component: () => import('@/views/car-master-status/CarMasterStatusForm.vue'),
//   meta: {
//     pageTitle: 'ปรับปรุงสถานะรถยนต์',
//     pageIcon: 'BoldIcon',
//     breadcrumb: [{
//       text: 'ปรับปรุงสถานะรถยนต์',
//       to: { name: 'car-master-status' },
//     },
//     {
//       text: 'update',
//       active: true,
//     },
//     ],
//   },
// },
{
  path: '/car_master_status/form/:regnum1/:regnum2',
  name: 'car-master-status-form-update',
  component: () => import('@/views/car-master-status/CarMasterStatusForm.vue'),
  meta: {
    pageTitle: 'ปรับปรุงสถานะรถยนต์',
    pageIcon: 'BoldIcon',
    breadcrumb: [{
      text: 'ปรับปรุงสถานะรถยนต์',
      to: { name: 'car-master-status' },
    },
    {
      text: 'update ข้อมูล',
      active: true,
    },
    ],
  },
},
]
