export default [{
  path: '/system',
  name: 'system',
  component: () => import('@/views/system/System.vue'),
  meta: {
    resource: 'System',
    action: 'read',
    pageTitle: 'ระบบ',
    pageIcon: 'CpuIcon',
    breadcrumb: [{
      text: 'ระบบ',
      active: true,
    },
    ],
  },
},
{
  path: '/system/form',
  name: 'system-form',
  component: () => import('@/views/system/SystemForm.vue'),
  meta: {
    resource: 'System',
    action: 'read',
    pageTitle: 'ระบบ',
    pageIcon: 'CpuIcon',
    navActiveLink: 'system',
    breadcrumb: [{
      text: 'ระบบ',
      to: { name: 'system' },
    },
    {
      text: 'เพิ่มข้อมูล',
      active: true,
    },
    ],
  },
},
{
  path: '/system/form/:id',
  name: 'system-form-edit',
  component: () => import('@/views/system/SystemForm.vue'),
  meta: {
    resource: 'System',
    action: 'read',
    pageTitle: 'ระบบ',
    pageIcon: 'CpuIcon',
    navActiveLink: 'system',
    breadcrumb: [{
      text: 'ระบบ',
      to: { name: 'system' },
    },
    {
      text: 'แก้ไขข้อมูล',
      active: true,
    },
    ],
  },
},
{
  path: '/system/sort',
  name: 'system-sort',
  component: () => import('@/views/system/SystemSort.vue'),
  meta: {
    resource: 'System',
    action: 'read',
    pageTitle: 'ระบบ',
    pageIcon: 'CpuIcon',
    navActiveLink: 'system',
    breadcrumb: [{
      text: 'ระบบ',
      to: { name: 'system' },
    },
    {
      text: 'จัดเรียงข้อมูล',
      active: true,
    },
    ],
  },
},
]
